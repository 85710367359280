import Footer from "../footer/Footer";
import Header from "../header/Header";
import Menu from "../menu/Menu";
import Menu1 from "../menu/Menu1";
import { Contact } from "./Contact";

export const ContactWrapper = () => {
    return (
      <>
        <Header />
        <Menu />
        <Menu1 />
        <Contact />
        <Footer />
      </>
    );
  }
  
  export default ContactWrapper;