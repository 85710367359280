import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Menu from "../../menu/Menu";
import Menu1 from "../../menu/Menu1";
import { UgadiEvent } from "./UgadiEvent";


export const UggadiEventWrapper = () => {
    return (
      <>
        <Header />
        <Menu />
        <Menu1 />
        <UgadiEvent />
        <Footer />
      </>
    );
  }
  
  export default UggadiEventWrapper;