import React from 'react';
import './Menu.css'; // Import your CSS file for styling
const Menu = () => { 

  // const menuStructure1 = {
  //   "items": [{
  //     "name": "Home",
  //     "parent": false,
  //     "ref": "home"
  //   },
  //   {
  //     "name": "About",
  //     "parent": true,
  //     "children": [
  //       {
  //         "name": "\u00A0\u00A0History\u00A0\u00A0",
  //         "ref": "history"
  //       },
  //       {
  //         "name": "Team",
  //         "ref": "team"
  //       }
  //     ]
  //   },
  //   {
  //     "name": "Events",
  //     "parent": true,
  //     "children": [
  //       {
  //         "name": "Ugadi 2024",
  //         "ref": "ugadi2024"
  //       },
  //       {
  //         "name": "Gallery",
  //         "ref": "gallery"
  //       }
  //     ]
  //   }, 
  //   {
  //     "name": "Members",
  //     "parent": true,
  //     "children": [
  //       {
  //         "name": "Membership",
  //         "ref": "membership"
  //       }
  //     ]
  //   },       
  //   {
  //     "name": "Sponsors",
  //     "parent": false,
  //     "ref": "sponsors"
  //   },
  //   {
  //     "name": "Contact",
  //     "parent": false,
  //     "ref": "contact"
  //   }
  // ]
  // };

// return (
//   <>
//   <nav className="menu-bar">
//   <ul className="menu">
//   {menuStructure.items.map((item) => {
//     if(item.parent) {
//       return (
//         <li key={item.name} className="menu-item with-submenu">{item.name}
//           <ul key={item.name} className="submenu">
//             {item.children?.map((child) => {
//               return (<li key={child.name}><a href={child.ref}>{child.name}</a></li>)
//             })}
//           </ul>
//         </li>)
//     } else {
//       return <li key={item.name} className="menu-item"><a href={item.ref}>{item.name}</a></li>
//     }
//   })}
// </ul>
// </nav>
//   </>
// );


  return (
    <nav className="menu-bar">
      <ul className="menu">
        <li className="menu-item">
          <a href="home">Home</a>
        </li>
        <li className="menu-item with-submenu">About
          {/* <a href="about">About</a> */}
          <ul className="submenu">
            <li><a href="history">&nbsp;&nbsp;History&nbsp;&nbsp;</a></li>
            <li><a href="team">Team</a></li>
          </ul>
        </li>
        <li className="menu-item with-submenu">
          <a href="event">Events</a>
          <ul className="submenu">
            <li><a href="ugadi2024">Ugadi 2024</a></li>
            <li><a href="gallery">Gallery</a></li>
          </ul>
        </li>
        <li className="menu-item with-submenu">
          <a href="event">Members</a>
          <ul className="submenu">
            <li><a href="membership">Membership</a></li>
          </ul>
        </li>      
        <li className="menu-item">
          <a href="sponsors">Sponsors</a>
        </li>  
        <li className="menu-item">
          <a href="contact">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

// };
export default Menu;
