import './Footer.css';
import insta from './insta.png';
import facebook from './facebook.png';

export const Footer = () => {
  return (
    <>
      <footer className='footer1'>
        <div>&copy; 2024 WTA. All rights reserved.</div>
        <div className="social-links">
  <a href="https://www.facebook.com/profile.php?id=61552488296736" target="_blank" rel="noopener noreferrer">
    <img src={facebook} alt="Facebook" />
  </a>
  <a href="https://www.instagram.com/woodleateluguassociation" target="_blank" rel="noopener noreferrer">
    <img src={insta} alt="Instagram" />
  </a>
</div>

      </footer>
    </>
  );
}

export default Footer;
