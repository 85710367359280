import React from 'react';
import './Team.css';
import './Styles.css';

import { Container, Row, Col } from 'react-bootstrap';

const Team = () => {
    const items = [
        { name: 'Ajay Peesapati', image: require('./ajayp.png') },
        { name: 'Anil Korada', image: require('./anilk.png') },
        { name: 'Apparao Vegi', image: require('./apparao.png') },
        { name: 'Arjun Karumanchi', image: require('./user1.png') },
        { name: 'Dheeraj Kanna', image: require('./dheeraj.png') },
        { name: 'Gopikrishna Gujjula', image: require('./user1.png') },
        { name: 'Hari Kotla', image: require('./user1.png') },
        { name: 'Inakul Tirunagari', image: require('./inakul.png') },
        { name: 'Kamal Vudathu', image: require('./kamal.png') },
        { name: 'Kishore Gajula', image: require('./user1.png') },
        { name: 'Krishna Valaboju', image: require('./user1.png') },
        { name: 'Madhu Paridi', image: require('./user1.png') },
        { name: 'Mouli Nallajarla', image: require('./user1.png') },
        { name: 'Purna', image: require('./user1.png') },
        { name: 'Prasad Patlola', image: require('./user1.png') },
        { name: 'Prasanth Gandra', image: require('./user1.png') },
        { name: 'Praveen Doodagundla', image: require('./user1.png') },
        { name: 'Praveen Gurugubelli', image: require('./praveeng.png') },
        { name: 'Praveen Mandala', image: require('./user1.png') },
        { name: 'Rajnikanth Yarrapureddy', image: require('./user1.png') },
        { name: 'Sameer Gundu', image: require('./sameerg.png') },
        { name: 'Santosh', image: require('./santosh.png') },
        { name: 'Siva Naga Prasad', image: require('./user1.png') },
        { name: 'Siva Vallabhaneni', image: require('./user1.png') },
        { name: 'Sudha Jangam', image: require('./user1.png') },
        { name: 'Sudhir Etagowni', image: require('./user1.png') },
        { name: 'Vishwa', image: require('./user1.png') },
    ];

    const cols = 12;

    return (
        <div className='teamContainer'>
        <h4 className='teamHeader'>Committee Members for the year 2023</h4>
        <h5 className='teamSubheader'>(in alphabetical order)</h5>
        <Container>
            {[...Array(Math.ceil(items.length / cols))].map((_, rowIndex) => (
                <Row key={rowIndex}>
                    {/* Render cols columns for each row */}
                    {[...Array(cols)].map((_, colIndex) => {
                        const dataIndex = rowIndex * cols + colIndex;
                        // Check if dataIndex is within the range of data length
                        if (dataIndex < items.length) {
                            const item = items[dataIndex];
                            return (
                                <Col key={colIndex} sm="1" md="1" xl="1"  className='member-column'>
                                    {/* Render item content  */}
                                    
                                    <img src={item.image} alt={item.name} className='member-photo member-photo-mini'/>
                                    <span className="member">{item.name}</span>
                                </Col>
                            );
                        } else {
                            // Render an empty column if no data available
                            return <Col key={colIndex}></Col>;
                        }
                    })}
                </Row>
            ))}
        </Container>
        </div>
    )
};

export default Team;
