import Footer from "../footer/Footer";
import Header from "../header/Header";
import Menu from "../menu/Menu";
import { About } from "./About";


export const AboutWrapper = () => {
    return (
      <>
        <Header />
        <Menu />
        <About />
        <Footer />
      </>
    );
  }
  
  export default AboutWrapper;