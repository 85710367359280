import React from "react";
import './Contact.css';

export const Contact = () => {
    return (
        <div className="contactWrapper">
        <p className="contact">If you need any further details or assistance, please send an email to <a href="mailto:info@woodleatelugu.org.au">info@woodleatelugu.org.au</a> and one of our friendly committee members will respond to you promptly.
        </p>
        </div>
    );
}; 