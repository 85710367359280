import React, { useState } from 'react';
import './ImageThumbnail.css'; // Import CSS for styling
import hamburgerclose from '../menu/hamburgerclose.svg';


const ImageThumbnail = (props:any) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="image-thumbnail">
      <a href="#" onClick={togglePopup}>
        <img src={props.src} alt={props.alt} />
      </a>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <img src={props.src} alt={props.alt} className='full-image'/>
            {/* <button onClick={togglePopup}>Close</button> */}
            <button className="close-hamburger" onClick={togglePopup}>
                    <img src={hamburgerclose} className="x-hamburger" alt="" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageThumbnail;
