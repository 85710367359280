import './Home.css';

export const Home = () => {
  return (
    <>
    <div className='home'/>
    </>
  );
}

export default Home;
