import Footer from "../footer/Footer";
import Header from "../header/Header";
import Menu from "../menu/Menu";
import Menu1 from "../menu/Menu1";
import { Membership } from "./Membership";


export const MembershipWrapper = () => {
    return (
      <>
        <Header />
        <Menu />
        <Menu1 />
        <Membership />
        <Footer />
      </>
    );
  }
  
  export default MembershipWrapper;