
import React from "react";
import './About.css';

export const About = () => {
    return (
        <div className="aboutWrapper">
            <p>
            Established in 2023 by a passionate group of individuals, the Woodlea Telugu Association (WTA) operates as a non-profit organization. <br />Incorporated in the same year, WTA is dedicated to serving the Telugu families residing in the Woodlea and Booniebrook suburbs.
            
            <br/>With a primary focus on preserving and promoting the tradition and culture to younger generations, WTA offers a platform for showcasing diverse talents within the Telugu community of Woodlea and Booniebrook.
            </p>
        </div>
    );
}; 
