import Footer from "../footer/Footer";
import Header from "../header/Header";
import Menu1 from "../menu/Menu1";
import Menu from "../menu/Menu";
import SponsorRibbon from "../sponsorribbon/SponsorRibbon";
import Home from "./Home";

export const HomeWrapper = () => {
    return (
      <>
        <Header />
        <Menu />
        <Menu1 />
        <Home />
        <SponsorRibbon />
        <Footer />
      </>
    );
  }
  
  export default HomeWrapper;