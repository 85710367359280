import Footer from "../footer/Footer";
import Header from "../header/Header";
import Menu from "../menu/Menu";
import Menu1 from "../menu/Menu1";
import Sponsors from "./Sponsors";

export const SponsorWrapper = () => {
    return (
      <>
        <Header />
        <Menu />
        <Menu1 />
        <Sponsors />
        <Footer />
      </>
    );
  }
  
  export default SponsorWrapper;