
import React from "react";
import './Membership.css';

export const Membership = () => {
    return (
        <div className="membership-wrapper">
            <h4>Membership Benefits:</h4>
            <br />
            <ul>
            <li>
            WTA conducts 4 major events (Ugadi, Vinayaka Chavithi, Deepavali, Sankranthi) and 5 mini events (School Holidays, Dasara, ?) per year. Members will get free access to all the mini events and discounted price for the major events.
            The School Holiday program runs during end of school terms 1, 2 and 3.
            </li>
            <li>
                Avail discounts from various sponsors / businesses that are tied up with the association
            </li>    
            <li>
            Parents or immediate family of the members, who are on a  short duration visit, can attend the event with a minimal fee per event.
            </li>
            <li>
            Parents who are aged more than 65 years, and permanently moved to stay with their children in Woodlea/Bonniebrook, can avail annual membership at $50 per couple / $25 per individual.
            </li>
            <li>
            Be a proud supporter / volunteer and encourage future generations to stabilize and understand the core values, culture and tradition of Telugu community
            </li>
            <li>
            Corpus funds - To support events and help procure stationary, cutlery, Tea / Coffee kegs etc.
            </li>
            </ul>
            <br/>
            Membership drive runs twice a year, once in December (50% off on regular price) and in July of each year. 
            To become a member, please contact any of the committee members.
            <br />Alternatively, you can express your interest by submitting your details using this&nbsp;  
            <a href="https://form.jotform.com/aintreetelugu/wta-reg-form">registration</a> form. 
        </div>
    );
}; 
