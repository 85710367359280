// Menu.js
import React, { useState } from 'react';
import './Menu1.css';
import hamburger from './hamburger.svg';
import hamburgerclose from './hamburgerclose.svg';


const Menu1 = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [membership, setMembership] = useState(false);
  const [about, setAbout] = useState(false);
  const [event, setEvent] = useState(false);


  const toggleMenu = () => {
     setShowMenu(!showMenu);
     setMembership(false);
     setEvent(false);
     setAbout(false);
  };

  const toggleAboutMenu = () => {
    setAbout(!about);
    setMembership(membership ? !membership : membership);
    setEvent(event ? !event : event);
  };

  const toggleEventMenu = () => {
    setEvent(!event);
    setMembership(membership ? !membership : membership);
    setAbout(about ? !about : about);
  };

  const toggleMembershipMenu = () => {
    setMembership(!membership);
    setEvent(event ? !event : event);
    setAbout(about ? !about : about);
  };

  // return (
  //   <div className="menu-container-mini">
  //     <button className="menu-button-mini" onClick={toggleMenu}>
  //       <img src={hamburger} alt="" />
  //     </button>
  //     {showMenu && (
  //       <div className="menu-mini">
  //           <div className='hamClose'>
  //               <button className="hamburger-close" onClick={toggleMenu}>
  //                   <img src={hamburgerclose} alt="" />
  //               </button>
  //           </div>
  //         <ul>
  //           {menuStructure.items.map((item) => {
  //             if (!item.parent) {
  //               // return <></>
  //               return (<li key={item.name}><a href={item.ref}>{item.name}</a></li>)
  //             } else {
  //               return <></>
  //               // if (item.name === 'About') {
  //               // return (<>
  //               //   <li key={item.name} onClick={toggleAboutMenu}>{item.name} <span className={about ? "exp" : "col"}></span></li>
  //               //       {about && <ul key={item.name} className="sub-menu-mini">
  //               //         {item.children?.map((child) => {
  //               //           return (<li key={child.name}><a href={child.ref}>{child.name}</a></li>)
  //               //         })}
  //               //       </ul>
  //               //       }
  //               //       </>
  //               //   )
  //               // } else if (item.name === 'Events') {
  //               //   return (<>
  //               //     <li key={item.name} onClick={toggleEventMenu}>{item.name} <span className={event ? "exp" : "col"}></span></li>
  //               //         {event && <ul key={item.name}  className="sub-menu-mini">
  //               //           {item.children?.map((child) => {
  //               //             return (<li key={item.name}><a href={child.ref}>{child.name}</a></li>)
  //               //           })}
  //               //         </ul>
  //               //         }
  //               //     </>)
  //               // } else if (item.name === 'Members'){
  //               //   return (<>
  //               //     <li key={item.name} onClick={toggleMembershipMenu}>{item.name} <span className={event ? "exp" : "col"}></span></li>
  //               //         {membership && <ul key={item.name}  className="sub-menu-mini">
  //               //           {item.children?.map((child) => {
  //               //             return (<li key={item.name}><a href={child.ref}>{child.name}</a></li>)
  //               //           })}
  //               //         </ul>
  //               //         }
  //               //     </>)
  //               // }
  //             }
  //           })}
  //         </ul>
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <div className="menu-container-mini">
      <button className="menu-button-mini" onClick={toggleMenu}>
        <img src={hamburger} alt="" />
      </button>
      {showMenu && (
        <div className="menu-mini">
            <div className='hamClose'>
                <button className="hamburger-close" onClick={toggleMenu}>
                    <img src={hamburgerclose} alt="" />
                </button>
            </div>
          <ul>
          <li><a href="home">Home </a></li>
            <li onClick={toggleAboutMenu}>About <span className={about ? "exp" : "col"}></span></li>
            {about && (
              <ul className="sub-menu-mini">
                <li><a href="history">History</a></li>
                <li><a href="team">Team</a></li>
              </ul>
            )}          
            <li onClick={toggleEventMenu}>Events <span className={event ? "exp" : "col"}></span></li>
            {event && (
              <ul className="sub-menu-mini">
                <li><a href="ugadi2024">Ugadi 2024</a></li>
                <li><a href="gallery">Gallery</a></li>
              </ul>
            )}
            <li onClick={toggleMembershipMenu}>Members <span className={membership ? "exp" : "col"}></span></li>
            {membership && (
              <ul className="sub-menu-mini">
                <li><a href="membership">Membership</a></li>
              </ul>
            )} 
            <li><a href="sponsors">Sponsors</a></li>
            <li><a href="contact">Contact</a></li>           
          </ul>
        </div>
      )}
    </div>
  );
};

export default Menu1;
