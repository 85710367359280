import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import AboutWrapper from './components/about/AboutWrapper';
import ContactWrapper from './components/contact/ContactWrapper';
import HomeWrapper from './components/home/HomeWrapper';
import TeamWrapper from './components/team/TeamWrapper';
import SponsorWrapper from './components/sponsors/SponsorWrapper';
import HistoryWrapper from './components/history/HistoryWrapper';
import GalleryWrapper from './components/gallery/GalleryWrapper';
import MembershipWrapper from './components/membership/MembershipWrapper';
import UgadiEventWrapper from './components/event/ugadi24/UgadiEventWrapper';

function App() {
  return (
    <div className='App'>
    <Routes>
    <Route path='/wta' element={<HomeWrapper />} />  
    <Route path='/about' element={<AboutWrapper />} />
    <Route path='/contact' element={<ContactWrapper />} />
    <Route path='/home' element={<HomeWrapper />} />
    <Route path='/team' element={<TeamWrapper />} />
    <Route path='/history' element={<HistoryWrapper />} />
    <Route path='/gallery' element={<GalleryWrapper />} />
    <Route path='/sponsors' element={<SponsorWrapper />} />
    <Route path='/membership' element={<MembershipWrapper />} />
    <Route path='/ugadi2024' element={<UgadiEventWrapper />} />
    
  </Routes>
  </div>
  );
}

export default App;
