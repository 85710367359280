
import React from "react";
import './UgadiEvent.css';

export const UgadiEvent = () => {
    return (
        <div className="ugadi-event-wrapper">
            <h4>Ugadi 2024</h4>
            <br />
            <b>Date:</b> 14 April 2024, from 11am onwards <br />
            <b>Location:</b> Melton Community Hall, 238 High Street, Melton - 3337 <br />
            <b>Registration: </b><a href="https://form.jotform.com/240642624617859" target="_blank">Registration form</a><br />
            <b>Event organisers:</b> Siva Prasad, Santhosh <br />
            <b>Cultural organisers:</b> Sudha, Mrudula <br />
            <br /><br />
            <p>
                After you have registered and received an email, please click  <a href="https://ugadi2024.carrd.co/" target="_blank">here</a> to make the payment. 
                Please select <b>WTA member pay</b> if you are a WTA member.  Otherwise, choose <b>WTA non-member pay</b>.
                Make sure the details are correct before making the payment.  You <b>must</b> provide the same
                email address you registered with for the event.
                </p>
            
            <p>
            We are looking for lots of volunteers to help at the event.  Please sign up 
            &nbsp;<a href="https://docs.google.com/spreadsheets/d/1esHRH0SZ8oJJzXzq-nOwqTh2k4R8ISz4C398UJg-4VE/edit?usp=sharing" target="_blank">here</a> or contact any of the event organisers.
            </p>
        </div>
    );
}; 
