import React from 'react';
import './Sponsors.css';

const Sponsors = () => {

    const platinum = [
        {'name': 'Ommax', url: 'https://ommax.com.au/', 'image':require('../sponsorribbon/ommax.png')},
    ];

    const mcc = [
        {'name': 'Melton City Council', url: 'https://www.melton.vic.gov.au/', 'image':require('../sponsorribbon/mcc.png')},
    ];

    const gold = [
        {'name': 'YesYes', url: 'https://yesyesindiansupermarket.com.au/', 'image':require('../sponsorribbon/yesyes.png')},
        {'name': 'Dosahut', url: 'https://www.dosahut.net.au/', 'image':require('../sponsorribbon/dh1.png')},
        {'name': 'Asac', url: 'https://www.asacpropertygroup.com.au/', 'image':require('../sponsorribbon/asac.png')},
        {'name': 'Aussie Greenmarks', url: 'https://www.aussiegreenmarks.com.au/', 'image':require('../sponsorribbon/ausiegreen.png')}
    ];     

    const bronze = [
        {'name': 'Visa Finder', url: 'http://www.visafinder.com.au/', 'image':require('../sponsorribbon/visafinder.png')},
        {'name': 'Neva Fitness', url: 'https://www.facebook.com/nevafitness/', 'image':require('../sponsorribbon/nevafitness.png')},
        {'name': 'Natyapriya', url: 'https://m.facebook.com/people/Natyapriya/100067404641692/', 'image':require('../sponsorribbon/natyapriya.png')}    ]; 
    const cols = 3;

  return (
    <div>
        <div className='mcclabel'>
        WTA would like to extend heartfelt thanks to the Melton City Council for their support in building this website and instrumental in bringing our vision to life. We are deeply appreciative of your commitment to our community.
        </div>
    <div key="platinum">
        <div className='sponsorlabel'>Website Sponsors</div>
        <div className="sponsor"><a href={mcc[0].url} target="_blank" rel="noreferrer"><img className="sponsorlogo" src={mcc[0].image} alt={mcc[0].name} title={mcc[0].name}></img></a></div>
    </div>

    <div key="platinum">
        <div className='sponsorlabel'>Platinum Sponsors</div>
        <div className="sponsor"><a href={platinum[0].url} target="_blank" rel="noreferrer"><img className="sponsorlogo" src={platinum[0].image} alt={platinum[0].name} title={platinum[0].name}></img></a></div>
    </div>
    <div >
        <div className='sponsorlabel'>Gold Sponsors</div>
        <div className="goldcontainer">
        {gold.map((sponsor,i) => {
            return <div key={'gold-container-sponsor-'+i} className="sponsor"><a href={sponsor.url} target="_blank" rel="noreferrer"><img className="sponsorlogo" alt={sponsor.name} src={sponsor.image} title={sponsor.name}></img></a></div>
        })}
        </div>
    </div>  
   
    <div key="bronze">
        <div className='sponsorlabel' key='blah'>
            Bronze Sponsors
        </div>
        {[...Array(Math.ceil(bronze.length / cols))].map((_, rowIndex) => (
            <div className='goldcontainer' key={'bronze-container-div-'+rowIndex}>
                {/* Render cols columns for each row */}
                {[...Array(cols)].map((_, colIndex) => {
                    const dataIndex = rowIndex * cols + colIndex;
                    // Check if dataIndex is within the range of data length
                    if (dataIndex < bronze.length) {
                        const item = bronze[dataIndex];
                        return (
                            <div key={'bronze-container-sponsor-'+colIndex} className="sponsor"><a href={item.url} target="_blank" rel="noreferrer"><img className="sponsorlogo" alt={item.name} src={item.image} title={item.name}></img></a></div>
                        );
                    } else {
                        // Render an empty column if no data available
                        return <div key={'bronze-container-sponsor-'+colIndex} className="sponsor"></div>;
                    }
                })}
            </div>
        ))}
    </div>  
    
    <br />
    
    <div>
    For all Sponsorship enquiries, please contact the Sponsorship Managers Rajnikanth & Mouli garu
    </div>
    <br />
    <br />
    <br />
    <br />
</div>  
  );
};

export default Sponsors;
