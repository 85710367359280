// App.js


import React, { useState } from 'react';
import './Gallery.css';
import ImageThumbnail from './ImageThumbnail';


const Tab = (props: any) => (
  <div className="tab-content">

    {props.images && props.images.map((image: string | undefined, index: React.Key | null | undefined) => (
      <ImageThumbnail src={image} alt="Image" key={'image-thumbnail-'+index}/>
    ))}
    
  </div>
);

const TabInterface = () => {
  const [activeTab, setActiveTab] = useState(0);

  const ugadi2024Pics = [
    require('./ugadi24/comingsoon.png'),
  ];
  const diwaliPics = [
    require('./diwali/DSC_2739.jpg'),
    require('./diwali/DSC_2741.jpg'),
    require('./diwali/DSC_3596.jpg'),
    require('./diwali/DSC_3773.jpg'),
    require('./diwali/DSC_4007.jpg'),
    require('./diwali/DSC_4097.jpg'),
    require('./diwali/DSC_4101.jpg'),
    require('./diwali/DSC_4179.jpg'),
    require('./diwali/DSC_4194.jpg')
  ];

  const vc23Pics = [
    require('./vc23/DCS_0106.jpg'),
    require('./vc23/DCS_0172.jpg'),
    require('./vc23/DCS_0226.jpg'),
    require('./vc23/DCS_0298.jpg'),
    require('./vc23/DCS_0304.jpg'),
    require('./vc23/DCS_0371.jpg'),
    require('./vc23/DCS_0386.jpg'),
    require('./vc23/DCS_0399.jpg'),
    require('./vc23/DCS_0409.jpg')
  ];

  const tabs = [
    { tabName: 'Ugadi 2024', images: ugadi2024Pics },
    { tabName: 'Diwali 2023', images: diwaliPics },
    { tabName: 'Vinayaka Chavithi 2023', images: vc23Pics },
  ];

  return (
    
    <div>
    <br />
      <div className="tabs">
        {tabs.map((tab, index) => (
            <div key={'tabs-container-' + index} className={( activeTab === index ? 'tab active' : 'tab' )} onClick={() => setActiveTab(index)}>{tab.tabName}</div>
        ))}
      </div>
      <Tab tabName={tabs[activeTab].tabName} images={tabs[activeTab].images} />
    </div>
  );
};

export default TabInterface;


// import React, { useState } from 'react';
// import './Gallery.css';

// import TabContent from './GalleryContent';

// const GalleryInterface = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   const handleTabClick = (index: React.SetStateAction<number>) => {
//     setActiveTab(index);
//   };

//   return (
//     <div>
//       <div className="tabs">
//         {[0, 1, 2].map((index) => (
//           <div
//             key={index}
//             className={`tab ${activeTab === index ? 'active' : ''}`}
//             onClick={() => handleTabClick(index)}
//           >
//             Tab {index + 1}
//           </div>
//         ))}
//       </div>
//       <TabContent/>
//     </div>
//   );
// };

// export default GalleryInterface;
