import Footer from "../footer/Footer";
import Header from "../header/Header";
import Menu from "../menu/Menu";
import Menu1 from "../menu/Menu1";
import GalleryInterface from "./GalleryInterface";

export const GalleryWrapper = () => {
    return (
      <>
        <Header />
        <Menu />
        <Menu1 />
        <GalleryInterface />
        <Footer />
      </>
    );
  }
  
  export default GalleryWrapper;