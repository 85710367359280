
import React from "react";
import './History.css';

export const History = () => {
    return (
        <div className="history-wrapper">
            <p>
            The Woodlea Telugu Association (WTA), established as a non-profit organization in 2023, 
            is dedicated to addressing the needs of the Telugu communities residing in the Woodlea 
            and Bonniebrook suburbs of Western Melbourne. Its core mission centers around the preservation 
            and propagation of traditional culture, particularly aimed at younger generations. 
            WTA provides a platform for its members to exhibit their diverse talents. 
            <br/>Started by a group of enthusiastic individuals, WTA operates on a flat structure and 
            currently boasts a membership of 100 families.
            </p>    
        </div>
    );
}; 
