import React, { useRef } from 'react';
import './SponsorRibbon.css';

const images = [
    {'url':require('./ommax.png')},
    {'url':require('./mcc.png')},

    {'url':require('./dh1.png')},
    {'url':require('./yesyes.png')},
    {'url':require('./asac.png')},    
    {'url':require('./ausiegreen.png')},
    {'url':require('./visafinder.png')},
    {'url':require('./nevafitness.png')},
    {'url':require('./natyapriya.png')}
    // {'url':require('./dh.png')},
    // {'url':require('./dh1.png')}
]

const HorizontalRibbon = () => {
  const ribbonRef = useRef<HTMLDivElement>(null);

  return (
    <div className="sponsorcontainer">
    <div className="sponsorLabel1"><h2>Our Sponsors </h2></div>
    <div className="ribbon-container">
      <div className="ribbon" ref={ribbonRef}>
        {images.map((imageUrl, index) => (
          <img key={index} src={imageUrl.url} alt="" />
        ))}
      </div>
    </div>
    </div>
  );
};

export default HorizontalRibbon;
